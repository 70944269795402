const STAGING = "dev"; // 'dev', 'prod', 'preview'

const KEY = {
  local: "9p8pcb0i6h3lkxfvaeb5jlnbw3ipnwcbi4hlaite7wzj0rhz",
  dev: "9p8pcb0i6h3lkxfvaeb5jlnbw3ipnwcbi4hlaite7wzj0rhz",
  prod: "uz85p64rd3ovocni9cerrcpe0bxz73q6n3x8b7prwquy1cvz",
  preview: "fopi001n7c5upf7tv6odomzf0gcn8mbk1oqjevwud97hzwwk",
};

export const Keys = {
    EditorKey: KEY[STAGING] || ""
}